<template>
  <div class="C_auth-root">
    <ConfcallrLogo color="white" />
    <form
      class="C_auth-box"
      @submit.prevent="resetPassword"
    >
      <router-link
        class="C_auth-cross-link"
        :to="{name: 'Login'}"
      >
        <img
          src="@/assets/img/auth-cross.svg"
          width="20"
          height="22"
        >
      </router-link>
      <template v-if="!isSuccess">
        <p class="C_auth-heading">
          {{ $t('ForgotPassword.heading') }}
        </p>
        <p class="C_auth-subline">
          {{ $t('ForgotPassword.subline') }}
        </p>
        <div class="C_auth-field-container">
          <ds-input
            v-model="email"
            type="email"
            :placeholder="$t('ForgotPassword.emailPlaceholder')"
            gray
            autofocus
          />
        </div>
        <ds-button
          size="medium"
          fluid
          :loading="loading"
          :disabled="disabled"
        >
          {{ $t('ForgotPassword.resetButton') }}
        </ds-button>
      </template>
      <template v-else>
        <p class="C_auth-heading">
          {{ $t('ForgotPassword.success.heading') }}
          {{ email }}
        </p>
        <p class="C_auth-subline">
          {{ $t('ForgotPassword.success.subline') }}
        </p>
        <div class="thumb-image-container">
          <img
            src="@/assets/img/thumb-up.svg"
            width="55"
            height="54"
          >
        </div>
      </template>
      <router-link
        class="C_auth-bottom-link"
        to="/login"
      >
        {{ $t('ForgotPassword.loginLink') }}
      </router-link>
    </form>
  </div>
</template>

<script>
import ConfcallrLogo from '@/components/ConfcallrLogo.vue'

export default {
  name: 'FortgotPassword',
  components: {
    ConfcallrLogo
  },
  data () {
    let email = ''

    if (this.$route.params.email) {
      email = this.$route.params.email
    }

    return {
      email,
      isSuccess: false,
      loading: false
    }
  },
  computed: {
    disabled () {
      return !this.email ||
        !this.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i)
    }
  },
  methods: {
    async resetPassword () {
      try {
        this.loading = true
        await this.$store.dispatch('account/resetPassword', { email: this.email })
        this.isSuccess = true
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/commons/auth.scss";

.thumb-image-container {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}
</style>
