<template>
  <div class="C_auth-root">
    <ConfcallrLogo color="white" />
    <form
      class="C_auth-box"
      @submit.prevent="resetPassword"
    >
      <router-link
        class="C_auth-cross-link"
        :to="{ name: 'Login' }"
      >
        <img
          src="@/assets/img/auth-cross.svg"
          width="20"
          height="22"
        >
      </router-link>
      <template v-if="!isSuccess">
        <p class="C_auth-heading">
          {{ $t('ResetPassword.heading') }}
        </p>
        <div class="C_auth-field-container">
          <ds-input
            v-model.trim="newPassword"
            type="password"
            :placeholder="$t('ResetPassword.newPassword')"
            required
          />
        </div>
        <div class="C_auth-field-container">
          <ds-input
            v-model="newPasswordBis"
            type="password"
            :placeholder="$t('ResetPassword.newPasswordBis')"
            required
          />
        </div>
        <ds-button
          size="medium"
          fluid
          :loading="loading"
          :disabled="!!localError"
        >
          {{ $t('ResetPassword.submitButton') }}
        </ds-button>
        <p
          v-if="error"
          class="error-message"
        >
          {{ error }}
        </p>
      </template>
      <template v-else>
        <p class="C_auth-heading">
          {{ $t('ResetPassword.successMessage') }}
        </p>
        <div class="thumb-image-container">
          <img
            src="@/assets/img/thumb-up.svg"
            width="55"
            height="54"
          >
        </div>
      </template>
      <router-link
        class="C_auth-bottom-link"
        to="/login"
      >
        {{ $t('ResetPassword.loginLink') }}
      </router-link>
    </form>
  </div>
</template>

<script>
import ConfcallrLogo from '@/components/ConfcallrLogo.vue'

export default {
  components: {
    ConfcallrLogo
  },
  data () {
    return {
      isSuccess: false,
      remoteError: false,
      loading: false,
      newPassword: '',
      newPasswordBis: ''
    }
  },
  computed: {
    localError () {
      if (this.newPassword !== this.newPasswordBis) {
        return this.$t('ResetPassword.errors.notIdentic')
      }

      if (this.newPassword.length > 0 && this.newPassword.length < 6) {
        return this.$t('ResetPassword.errors.tooShort')
      }

      return false
    },
    error () {
      if (this.localError) return this.localError
      if (this.remoteError) return this.remoteError

      return false
    }
  },
  watch: {
    newPassword () {
      this.remoteError = false
    },
    newPasswordBis () {
      this.remoteError = false
    }
  },
  methods: {
    async resetPassword () {
      if (this.localError) return

      try {
        this.remoteError = false
        this.loading = true
        await this.$store.dispatch('account/setPassword', {
          password: this.newPassword,
          token: this.$route.params.token
        })
        this.isSuccess = true
      } catch (err) {
        this.remoteError = err.message
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/commons/auth.scss";

.thumb-image-container {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

.error-message {
  margin-top: 10px;
  color: $color-red;
}
</style>
