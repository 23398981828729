<template>
  <div class="C_auth-root">
    <ConfcallrLogo color="white" />
    <form
      class="C_auth-box"
      @submit.prevent="register"
    >
      <a
        class="C_auth-cross-link"
        :href="websiteUrl"
      >
        <img
          src="@/assets/img/auth-cross.svg"
          width="20"
          height="22"
        >
      </a>
      <p class="C_auth-heading">
        {{ $t('Signup.heading') }}
      </p>
      <p class="C_auth-subline">
        {{ $t('Signup.subline') }}
      </p>
      <div class="C_auth-field-container">
        <ds-input
          v-model="email"
          type="email"
          :placeholder="$t('Signup.emailPlaceholder')"
          gray
          autofocus
        />
      </div>
      <div class="C_auth-field-container">
        <ds-input
          v-model.trim="name"
          type="text"
          :placeholder="$t('Signup.namePlaceholder')"
          gray
        />
      </div>
      <div class="C_auth-field-container">
        <ds-input
          v-model="password"
          type="password"
          :placeholder="$t('Signup.passwordPlaceholder')"
          gray
        />
        <p class="C_auth-subline">
          {{ $t('Signup.min6Characters') }}
        </p>
      </div>
      <ds-button
        size="medium"
        fluid
        :loading="loading"
        :disabled="disabled"
      >
        {{ $t('Signup.createAccountButton') }}
      </ds-button>
      <router-link
        class="C_auth-bottom-link"
        to="/login"
      >
        {{ $t('Signup.loginLink') }}
      </router-link>
    </form>
    <router-link
      class="without-account-link"
      to="/without-account"
    >
      <span>
        {{ $t('Signup.withoutAccountLink') }}
      </span>
      <img
        src="@/assets/img/chevron-blue-right.svg"
        width="24"
        height="24"
      >
    </router-link>
  </div>
</template>

<script>
import ConfcallrLogo from '@/components/ConfcallrLogo.vue'

export default {
  name: 'Signup',
  components: {
    ConfcallrLogo
  },
  data () {
    let email = ''

    if (this.$route.params.email) {
      // prefill email from '/start/xxx@xxx.com'
      email = this.$route.params.email
    }

    return {
      email,
      name: '',
      password: '',
      loading: false
    }
  },
  computed: {
    websiteUrl () {
      return process.env.VUE_APP_WEBSITE_URL
    },
    disabled () {
      return !this.email ||
        !this.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i) ||
        !this.name ||
        !this.password ||
        this.password.length < 6
    }
  },
  methods: {
    async register () {
      this.loading = true

      try {
        await this.$store.dispatch('session/register', {
          name: this.name,
          email: this.email,
          password: this.password
        })

        this.$router.replace({ name: 'Conferences' })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', {
          message: this.$t(`Errors.${err.message}`)
        })
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/commons/auth.scss";

.without-account-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 30px 30px 30px 20px;
  width: 450px;
  max-width: 100%;
  background-color: white;
  border-radius: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
</style>
