<template>
  <div class="C_auth-root">
    <ConfcallrLogo color="white" />
    <form
      class="C_auth-box"
      @submit.prevent="continueAsGuest"
    >
      <router-link
        class="C_auth-cross-link"
        :to="{name: 'Login'}"
      >
        <img
          src="@/assets/img/auth-cross.svg"
          width="20"
          height="22"
        >
      </router-link>
      <p class="C_auth-heading">
        {{ $t('WithoutAccount.heading') }}
      </p>
      <p class="C_auth-subline">
        {{ $t('WithoutAccount.hint') }}
      </p>
      <div class="C_auth-field-container">
        <ds-input
          v-model="email"
          type="email"
          :placeholder="$t('WithoutAccount.emailPlaceholder')"
          gray
          autofocus
        />
      </div>
      <ds-button
        size="medium"
        fluid
        :disabled="disabled"
        :loading="loading"
      >
        {{ $t('WithoutAccount.submitButton') }}
      </ds-button>
      <router-link
        class="C_auth-bottom-link"
        to="/signup"
      >
        {{ $t('WithoutAccount.signupLink') }}
      </router-link>
    </form>
  </div>
</template>

<script>
import ConfcallrLogo from '@/components/ConfcallrLogo.vue'

export default {
  name: 'WithoutAccount',
  components: {
    ConfcallrLogo
  },
  data () {
    let email = ''

    if (this.$route.params.email) {
      email = this.$route.params.email
    }

    return {
      email,
      loading: false
    }
  },
  computed: {
    disabled () {
      return !this.email ||
        !this.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i)
    },
    start () {
      return !!this.$route.params.start
    }
  },
  mounted () {
    if (this.start && !this.disabled) {
      this.continueAsGuest()
    }
  },
  methods: {
    async continueAsGuest () {
      this.loading = true
      try {
      // TODO: CHECK IF THIS EMAIL HAS AN ACCOUNT!
      // if it has, show confirmation!
        await this.$store.dispatch('session/loginAsGuest', {
          email: this.email
        })
        if (this.$route.params.redirect && this.$route.params.redirect.path) {
          this.$router.replace(this.$route.params.redirect)
        } else {
          this.$router.replace({ name: 'Conferences', params: { start: this.start } })
        }
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/commons/auth.scss";
</style>
