<template>
  <div class="C_auth-root">
    <ConfcallrLogo color="white" />
    <form
      class="C_auth-box"
      @submit.prevent="loginWithAccount"
    >
      <a
        class="C_auth-cross-link"
        :href="websiteUrl"
      >
        <img
          src="@/assets/img/auth-cross.svg"
          width="20"
          height="22"
        >
      </a>
      <p class="C_auth-heading">
        {{ $t('Login.heading') }}
      </p>
      <p class="C_auth-subline">
        {{ $t('Login.subline') }}
      </p>
      <div class="C_auth-field-container">
        <ds-input
          v-model="email"
          type="email"
          :placeholder="$t('Login.emailPlaceholder')"
          gray
          autofocus
        />
      </div>
      <div class="C_auth-field-container">
        <ds-input
          v-model="password"
          type="password"
          :placeholder="$t('Login.passwordPlaceholder')"
          gray
        />
        <router-link
          class="forgot-password-link"
          :to="{name: 'ForgotPassword', params: { email }}"
        >
          {{ $t('Login.forgotPasswordLink') }}
        </router-link>
      </div>
      <ds-button
        size="medium"
        fluid
        :loading="loading"
        :disabled="disabled"
      >
        {{ $t('Login.loginButton') }}
      </ds-button>
      <p class="or">
        {{ $t('Login.or') }}
      </p>
      <router-link
        class="without-account-link"
        :to="{name: 'WithoutAccount', params: { email, start, redirect: $route.params.redirect }}"
      >
        {{ $t('Login.withoutAccountLink') }}
      </router-link>
      <router-link
        class="C_auth-bottom-link"
        :to="{name: 'Signup', params: { email }}"
      >
        {{ $t('Login.signupLink') }}
      </router-link>
    </form>
  </div>
</template>

<script>
import ConfcallrLogo from '@/components/ConfcallrLogo.vue'

export default {
  name: 'Login',
  components: {
    ConfcallrLogo
  },
  data () {
    let email = ''
    let start = false

    if (this.$route.params.email) {
      // we're coming from /start
      // prefill email from '/start/xxx@xxx.com'
      email = this.$route.params.email
      start = true
    }

    return {
      email,
      start,
      password: '',
      loading: false
    }
  },
  computed: {
    websiteUrl () {
      return process.env.VUE_APP_WEBSITE_URL
    },
    disabled () {
      return !this.email ||
        !this.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i) ||
        !this.password
    }
  },
  methods: {
    async loginWithAccount () {
      this.loading = true

      try {
        await this.$store.dispatch('session/login', {
          login: this.email,
          password: this.password
        })

        this.authSuccessful()
      } catch (err) {
        let message = ''

        if (err.response && err.response.status === 401) {
          message = this.$t('Auth.invalid')
        } else {
          message = `${this.$t('Common.error')}: ${err.code} ${err.message}`
        }

        this.$store.commit('app/openErrorPopin', { message })
        this.loading = false
      }
    },
    authSuccessful () {
      if (this.$route.params.redirect && this.$route.params.redirect.path) {
        this.$router.replace(this.$route.params.redirect)
      } else {
        this.$router.replace({ name: 'Conferences' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/commons/auth.scss";

.forgot-password-link {
  display: inline-block;
  margin-top: 10px;
  margin-left: 15px;
  color: $color-blue;
  font-size: 12px;
  line-height: 16px;
}

.or {
  margin-top: 10px;
  color: $color-gray-soft;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.without-account-link {
  display: block;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  background-color: white;
  border: 1px solid $color-fake-white;
  border-radius: 10px;
  color: $color-gray-mid;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
</style>
